import { Component, Input, OnInit } from "@angular/core";
import {
  Availability,
  IAvailability,
} from "src/app/models/profiles/availability";

@Component({
  selector: "app-availability-table",
  templateUrl: "./availability-table.component.html",
  styleUrls: ["./availability-table.component.scss"],
})
export class AvailabilityTableComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Input() availabilities: Availability[] = [];
  days: AvailiabilityDay[] = [
    new AvailiabilityDay(0, "週日"),
    new AvailiabilityDay(1, "週一"),
    new AvailiabilityDay(2, "週二"),
    new AvailiabilityDay(3, "週三"),
    new AvailiabilityDay(4, "週四"),
    new AvailiabilityDay(5, "週五"),
    new AvailiabilityDay(6, "週六"),
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.availabilities.length == 0 && this.readonly == false) {
      this.selectAll();
      return;
    }

    this.availabilities.forEach((a) => {
      this.days[a.weekDay].initialize(a);
    });
  }

  get isSelectedAll(): boolean {
    return this.days.every((day) => day.isSelectedAll);
  }

  public selectAll(): void {
    if (this.isSelectedAll)
      this.days.forEach((day) =>
        day.times.forEach((t) => (t.selected = false)),
      );
    else
      this.days.forEach((day) => day.times.forEach((t) => (t.selected = true)));
  }

  getTimeslotAllSelected(index: number): boolean {
    return this.days.every((day) => day.times[index].selected);
  }

  selectAllTimeSlot(index: number): void {
    if (this.getTimeslotAllSelected(index))
      this.days.forEach((day) => (day.times[index].selected = false));
    else this.days.forEach((day) => (day.times[index].selected = true));
  }

  public getModels(): IAvailability[] {
    var result: IAvailability[] = [];
    this.days.forEach(
      (day) => (result = result.concat(day.toAvailabilityModel())),
    );
    return result;
  }
}

class AvailiabilityDay {
  public weekday: number;
  public name: string;
  public times: AvailabilityTime[];

  constructor(weekday: number, name: string) {
    this.weekday = weekday;
    this.name = name;
    this.times = [
      new AvailabilityTime(new Date(2000, 1, 9, 6)),
      new AvailabilityTime(new Date(2000, 1, 9, 8)),
      new AvailabilityTime(new Date(2000, 1, 9, 10)),
      new AvailabilityTime(new Date(2000, 1, 9, 12)),
      new AvailabilityTime(new Date(2000, 1, 9, 13)),
      new AvailabilityTime(new Date(2000, 1, 9, 15)),
      new AvailabilityTime(new Date(2000, 1, 9, 17)),
      new AvailabilityTime(new Date(2000, 1, 9, 19)),
    ];
  }

  initialize(availability: Availability): void {
    this.times.forEach(
      (t) =>
        (t.selected =
          t.selected ||
          (availability.availabilityStart.getHours() <= t.time.getHours() &&
            availability.availabilityEnd.getHours() > t.time.getHours())),
    );
  }

  selectAll(): void {
    if (this.isSelectedAll)
      this.times.forEach((time) => (time.selected = false));
    else this.times.forEach((time) => (time.selected = true));
  }

  public toAvailabilityModel(): IAvailability[] {
    var times: IAvailability[] = [];
    var start: number | null = null;
    var end: number | null = null;
    for (var i = 0; i < this.times.length; i++) {
      if (start == null && this.times[i].selected)
        start = this.times[i].time.getHours();
      if (start != null && this.times[i].selected == false)
        end = this.times[i].time.getHours();
      if (start != null && i == this.times.length - 1 && this.times[i].selected)
        end = 21;
      if (start != null && end != null) {
        times.push({
          uuId: null,
          weekDay: this.weekday,
          availabilityStart: new Date(2000, 1, 9, start).toJSON(),
          availabilityEnd: new Date(2000, 1, 9, end).toJSON(),
        });
        start = null;
        end = null;
      }
    }
    return times;
  }

  get isSelectedAll(): boolean {
    return this.times.every((time) => time.selected);
  }
}

class AvailabilityTime {
  public time: Date;
  public selected: boolean;

  constructor(time: Date) {
    this.time = time;
    this.selected = false;
  }
}
