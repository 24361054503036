<div class="side-nav" [ngClass]="{hide: toggle}">
  <p class="side-nav-item" routerLink="overview" routerLinkActive="active" translate>概覽</p>
  <p class="side-nav-menu-toggle" [ngClass]="{on: menu.staff}" (click)="menu.staff = !menu.staff"
    *ngIf="role == 'SuperAdmin'" translate>職員</p>
  <div class="nav-menu" [@dropdown]="menu.staff ? 'on' : 'off'" *ngIf="role == 'SuperAdmin'">
    <p class="side-nav-item" routerLink="staff/overview" routerLinkActive="active" translate>職員概覽</p>
    <p class="side-nav-item" routerLink="staff/create" routerLinkActive="active" translate>創建職員</p>
  </div>
  <div class="side-nav-menu-toggle" [ngClass]="{on: menu.user}" (click)="menu.user = !menu.user" translate>
    <p class="red-box" translate>會員<span class="red-rot" *ngIf="numSignUpUser!=0"></span></p>
  </div>
  <div class="nav-menu" [@dropdown]="menu.user ? 'on' : 'off'">
    <p class="side-nav-item" routerLink="user/overview" routerLinkActive="active" translate>會員概覽</p>
    <p class="side-nav-item" routerLink="user/create" routerLinkActive="active" translate>創建會員</p>
    <p class="side-nav-item red-box2" routerLink="user/sign-up-overview" routerLinkActive="active" translate>待審核列表<span
        class="red-rot-big" [ngClass]="{red_rot_padding: numSignUpUser>99}" *ngIf="numSignUpUser!=0">
        {{numSignUpUser <=9999?numSignUpUser:"9999+"}} </span>
    </p>
    <p class="side-nav-item" routerLink="user/sign-up-setting" routerLinkActive="active" *ngIf="role == 'SuperAdmin'"
      translate>帳號申請設置</p>
  </div>
  <div class="side-nav-menu-toggle" [ngClass]="{on: menu.activity}" (click)="menu.activity = !menu.activity" translate>
    <p class="red-box" translate>活動<span class="red-rot" *ngIf="numRequest!=0||numEvent!=0"></span></p>
  </div>
  <div class="nav-menu" [@dropdown]="menu.activity ? 'on' : 'off'">
    <p class="side-nav-item red-box2" routerLink="activity/overview/request" routerLinkActive="active" translate>
      義工服務<span class="red-rot-big" [ngClass]="{red_rot_padding: numRequest>99}" *ngIf="numRequest!=0">
        {{numRequest <=9999?numRequest:"9999+"}} </span>
    </p>
    <p class="side-nav-item red-box2" routerLink="activity/overview/event" routerLinkActive="active" translate>中心活動<span
        class="red-rot-big" [ngClass]="{red_rot_padding: numEvent>99}" *ngIf="numEvent!=0">
        {{numEvent <=9999?numEvent:"9999+"}} </span>
    </p>
    <p class="side-nav-item" routerLink="activity/create" routerLinkActive="active" translate>創建申請</p>
    <p class="side-nav-item" routerLink="activity/request-record" routerLinkActive="active" translate>新增歷史紀錄-義工服務</p>
    <p class="side-nav-item" routerLink="activity/event-record" routerLinkActive="active" translate>新增歷史紀錄-中心活動</p>
  </div>
  <p class="side-nav-menu-toggle" [ngClass]="{on: menu.announcement}" (click)="menu.announcement = !menu.announcement"
    translate>通告</p>
  <div class="nav-menu" [@dropdown]="menu.announcement ? 'on' : 'off'">
    <p class="side-nav-item" routerLink="announcement/overview" routerLinkActive="active" translate>通告概覽</p>
    <p class="side-nav-item" routerLink="announcement/create" routerLinkActive="active" translate>創建通告</p>
  </div>
  <div class="side-nav-menu-toggle" [ngClass]="{on: menu.redeem}" (click)="menu.redeem = !menu.redeem" translate>
    <p class="red-box" translate>獎勵兌換<span class="red-rot" *ngIf="numRedeem!=0"></span></p>
  </div>
  <div class="nav-menu" [@dropdown]="menu.redeem ? 'on' : 'off'">
    <p class="side-nav-item red-box2" routerLink="redeem/overview" routerLinkActive="active" translate>獎勵概覽<span
        class="red-rot-big" [ngClass]="{red_rot_padding: numRedeem>99}" *ngIf="numRedeem!=0">
        {{numRedeem <=9999?numRedeem:"9999+"}} </span>
    </p>
    <p class="side-nav-item" routerLink="redeem/create" routerLinkActive="active" translate>發布獎勵</p>
    <p class="side-nav-item" routerLink="redeem/setting" routerLinkActive="active" *ngIf="role == 'SuperAdmin'"
      translate>獎勵設置</p>
  </div>
  <p class="side-nav-menu-toggle" [ngClass]="{on: menu.donation}" (click)="menu.donation = !menu.donation" translate>捐分池
  </p>
  <div class="nav-menu" [@dropdown]="menu.donation ? 'on' : 'off'">
    <p class="side-nav-item" routerLink="donation/user" routerLinkActive="active" translate>會員捐分</p>
    <p class="side-nav-item" routerLink="donation/center" routerLinkActive="active" translate>中心捐分</p>
    <p class="side-nav-item" routerLink="donation/overview" routerLinkActive="active" translate>捐分紀錄</p>
  </div>
  <p class="side-nav-menu-toggle" [ngClass]="{on: menu.system}" (click)="menu.system = !menu.system"
    *ngIf="role == 'SuperAdmin'" translate>後台設置</p>
  <div class="nav-menu" [@dropdown]="menu.system ? 'on' : 'off'" *ngIf="role == 'SuperAdmin'">
    <p class="side-nav-item" routerLink="system/setting" routerLinkActive="active" translate>資料設置</p>
    <p class="side-nav-item" routerLink="system/application" routerLinkActive="active" translate>應用程式設置</p>
    <p class="side-nav-item" routerLink="system/user-template" routerLinkActive="active" translate>會員檔案模板</p>
    <p class="side-nav-item" routerLink="system/activity-template" routerLinkActive="active" translate>服務/活動模板</p>
    <p class="side-nav-item" routerLink="system/recommendation" routerLinkActive="active" translate>推薦設置</p>
  </div>
  <p class="side-nav-item" routerLink="analytics/report" routerLinkActive="active" translate>數據分析</p>
  <p class="side-nav-menu-toggle" [ngClass]="{on: menu.trash}" (click)="menu.trash = !menu.trash"
    *ngIf="role == 'SuperAdmin'" translate>垃圾桶</p>
  <div class="nav-menu" [@dropdown]="menu.trash ? 'on' : 'off'" *ngIf="role == 'SuperAdmin'">
    <p class="side-nav-item" routerLink="trash/request" routerLinkActive="active" translate>義工服務</p>
    <p class="side-nav-item" routerLink="trash/event" routerLinkActive="active" translate>中心活動</p>
    <p class="side-nav-item" routerLink="trash/user" routerLinkActive="active" translate>會員檔案</p>
    <p class="side-nav-item" routerLink="trash/donation" routerLinkActive="active" translate>捐分紀錄</p>
    <p class="side-nav-item" routerLink="trash/redeem" routerLinkActive="active" translate>獎勵兌換</p>
  </div>
  <p class="side-nav-item" (click)="openUserGuide()" translate>如何使用</p>

    <ng-container *ngIf="role == 'SuperAdmin' && usage">
        <div style="border-bottom: 1px solid #BFBFBF; width: 14.5rem;align-self: center;"></div>

        <div class="inline-flex-col text-grey" style="padding-left: 1.625rem;">
            <p class="mt-6">{{ '機構人數：' | translate: { value1: usage.nowUser, value2: usage.maxUser} }}</p>
            <p class="mt-6" translate>使用容量</p>
            <progress class="mt-2" [value]="usage.nowStore" [max]="usage.maxStore"></progress>
            <p class="mt-2 text-sm">{{ '已使用' | translate: { value1: usage.nowStore | number, value2: usage.maxStore } }}</p>
        </div>
    </ng-container>

</div>
<p class="mobile-toggle" [ngClass]="{'on': !toggle}" (click)="toggle = !toggle">></p>
