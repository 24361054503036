import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorHandleService } from "../services/error-handle/error-handle.service";
import { Router } from "@angular/router";
// import { ImportService } from '../services/import-export/import.service';

// Intercept error on http request
@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {
  public enabled: boolean = true;

  constructor(
    private errorHandleService: ErrorHandleService,
    // private importService: ImportService
    private router: Router,
  ) {
    // importService.interceptorSignal.subscribe(value => this.enabled = value);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error == null) return throwError(error);
        // TODO: do we allow permissive 403? e.g. user lacks permission to view certain elements?
        // match mobile app
        if (error.status == 403) {
          localStorage.removeItem("timebank_token");
          localStorage.removeItem("timebank_roles");
          this.router.navigate(["/login"]);
        }
        if (error.error["error_message"] && this.enabled)
          this.errorHandleService.errorSignal.emit(error.error);
        return throwError(error.error);
      }),
    );
  }
}
